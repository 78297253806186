import { FC, HTMLProps } from "react";
import styles from "./Checkbox.module.scss";
import classNames from "classnames";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import { NonBoolean } from "../../../utils/types";

export type Props = {
  checked: NonBoolean;
  setChecked: any;
  error: NonBoolean;
  blue?: NonBoolean;
  addclasses?: string[];
};

const Checkbox: FC<Props> = (props) => {
  const { t } = useTranslation("common");
  const { checked, setChecked, blue, error, addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    { [styles["blue"]]: blue, [styles["error"]]: error }
  );

  return (
    <div className={className}>
      <div className={styles["checkbox"]} onClick={setChecked}>
        <input type="checkbox" defaultChecked={Boolean(checked)} />
        <span className={styles["checkmark"]}></span>
      </div>
      <p>
        <span>{t("discuss.terms1")}</span>{" "}
        <Link href="/terms" target="_blank">
          {t("discuss.terms2")}
        </Link>
      </p>
    </div>
  );
};

export default Checkbox;
