import { FC, HTMLProps, useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames";
import { useFormik } from "formik";
import { gsap } from "gsap";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import * as Yup from "yup";

import { useAppProvider } from "../../../utils/appProvider";
import OutlinedButton from "../../Buttons/OutlinedButton/OutlinedButton";
import Checkbox from "../../Discuss/Checkbox";
import PhoneNumber from "../../PhoneField";
import Textarea from "../../Textarea";
import TextField from "../../TextField";

import styles from "./SendMessage.module.scss";
import { isSessionStorageEnabled } from "../../../utils/functions";

gsap.config({ nullTargetWarn: false });

export type Props = {
  addclasses?: string[];
} & HTMLProps<HTMLDivElement>;

const SendMessage: FC<Props> = (props) => {
  const { addclasses } = props;
  const className = classNames(
    [styles["container"], addclasses && [...addclasses]],
    {}
  );

  const {
    sendMessageOpen,
    setSendMessageOpen,
    setShowFeedback,
    sidebarOpen,
    setUtmTags,
    utmTags,
    setCloseSendMessageOnOutsideClick,
    closeSendMessageOnOutsideClick,
  } = useAppProvider();
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const router = useRouter();
  const wrapperRef = `.${styles["wrapper"]}`;
  const containerRef = `.${styles["container"]}`;
  const tl = gsap.timeline();

  const validation = Yup.object().shape({
    name2: Yup.string().required(),
    phone2: Yup.string().required().min(12).max(20),
    email2: Yup.string().email().required(),
    termsChecked2: Yup.bool().isTrue(),
  });

  const formik = useFormik({
    initialValues: {
      name2: "",
      company2: "",
      phone2: "",
      email2: "",
      comment2: "",
      termsChecked2: false,
    },
    validationSchema: validation,
    onSubmit: (data) => {
      const { name2, company2, phone2, email2, comment2 } = data;
      let source = undefined;
      let campaign = undefined;
      let medium = undefined;
      if (isSessionStorageEnabled()) {
        source = sessionStorage.getItem("utm_source");
        campaign = sessionStorage.getItem("utm_campaign");
        medium = sessionStorage.getItem("utm_medium");
      } else {
        source = utmTags["utm_source"];
        campaign = utmTags["utm_campaign"];
        medium = utmTags["utm_medium"];
      }
      const formatted = {
        name: name2,
        company: company2,
        phone: phone2,
        email: email2,
        comment: comment2,
        source,
        campaign,
        medium,
      };
      handleSubmit(formatted);
    },
  });

  const handleSubmit = async (data: { [key: string]: string | null }) => {
    setLoading(true);
    setError("");
    try {
      const res = await fetch("/api/contact", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (res.ok) {
        setShowFeedback(true);
        setSendMessageOpen(false);
        if (!sidebarOpen) {
          document.querySelector("html")!.style.overflowY = "overlay";
        }
        (window as any).ym(
          process.env.NEXT_PUBLIC_YANDEX_METRIC_ID,
          "reachGoal",
          "form_submission"
        );
        formik.resetForm();
      } else {
        if (res.status == 403) {
          throw new Error("requiredFieldsAreEmpty");
        } else {
          throw new Error("formError");
        }
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setCloseSendMessageOnOutsideClick(true);
    setSendMessageOpen((prev) => {
      if (prev) {
        if (!sidebarOpen) {
          document.querySelector("html")!.style.overflowY = "overlay";
        }
      } else {
        document.querySelector("html")!.style.overflowY = "hidden";
      }
      return !prev;
    });
  };

  const animateOpen = () => {
    tl.set(wrapperRef, { zIndex: 3000, opacity: 1 });
    tl.to(containerRef, { x: 0 });
  };

  const animateClose = () => {
    tl.to(containerRef, { x: "100%" });
    tl.set(wrapperRef, { zIndex: -1000, opacity: 0 });
  };

  useLayoutEffect(() => {
    if (sendMessageOpen) {
      animateOpen();
    } else {
      animateClose();
    }
    return () => {
      tl.clear();
    };
  }, [sendMessageOpen]);

  useEffect(() => {
    const { utm_source, utm_campaign, utm_medium, show_feedback_form } =
      router.query;
    const sessionIsEnabled = isSessionStorageEnabled();
    Object.entries({ utm_source, utm_campaign, utm_medium }).forEach(
      ([key, val]) => {
        if (val) {
          sessionIsEnabled
            ? sessionStorage.setItem(key, val.toString())
            : setUtmTags((prev) => {
                const copy = { ...prev };
                copy[`${key}`] = val;
                return copy;
              });
        }
      }
    );
    if (show_feedback_form) {
      setTimeout(() => {
        setSendMessageOpen(true);
      }, 200);
    }
  }, [router.query, setSendMessageOpen, setUtmTags]);

  return (
    <div className={styles["wrapper"]}>
      {closeSendMessageOnOutsideClick && (
        <div className={styles["outside-click"]} onClick={handleClose}></div>
      )}
      <div className={className} {...props}>
        <div className={styles["close"]} onClick={handleClose}></div>
        <h3>{t("send_message")}</h3>
        <form className={styles["form"]} onSubmit={formik.handleSubmit}>
          <TextField
            name="name2"
            id="name2"
            value={formik.values.name2}
            label={t("discuss.name")!}
            addclasses={[styles["name"]]}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("name2")}
            error={!!formik.errors.name2 && formik.touched.name2}
          />
          <TextField
            name="company2"
            id="company2"
            addclasses={[styles["company"]]}
            value={formik.values.company2}
            label={t("discuss.company")!}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("company2")}
            error={!!formik.errors.company2 && formik.touched.company2}
          />
          <PhoneNumber
            name="phone2"
            id="phone2"
            value={formik.values.phone2}
            addclasses={[styles["phone"]]}
            handleChange={(value: string) => {
              formik.setFieldValue("phone2", value);
            }}
            handleBlur={() => formik.setFieldTouched("phone2")}
            error={!!formik.errors.phone2 && formik.touched.phone2}
          />
          <TextField
            name="email2"
            id="email2"
            value={formik.values.email2}
            label="E-mail"
            addclasses={[styles["email"]]}
            handleChange={formik.handleChange}
            handleBlur={() => formik.setFieldTouched("email2")}
            error={!!formik.errors.email2 && formik.touched.email2}
          />
          <Textarea
            name="comment2"
            id="comment2"
            value={formik.values.comment2}
            label={t("discuss.comment")!}
            addclasses={[styles["comment"]]}
            handleChange={formik.handleChange}
          />
          <Checkbox
            checked={formik.values.termsChecked2 ? 1 : 0}
            setChecked={() =>
              formik.setFieldValue(
                "termsChecked2",
                !formik.values.termsChecked2
              )
            }
            error={
              !!formik.errors.termsChecked2 && !!formik.touched.termsChecked2
                ? 1
                : 0
            }
            blue={1}
            addclasses={[styles["checkbox"]]}
          />
          <OutlinedButton
            title={t("discuss.send")}
            addclasses={[styles["btn"]]}
            type="submit"
            light
          />
          {error && <p className={styles["error"]}>{t(`discuss.${error}`)}</p>}
        </form>
      </div>
    </div>
  );
};

export default SendMessage;
